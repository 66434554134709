<template>
  <ion-page>
    <ion-header :translucent="true" class="ion-no-border">
      <ion-toolbar>
        <ion-title>Home</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div id="container">
        <ion-text>Hi {{ userId }}</ion-text>

        <ion-button
          class="feature-buttons"
          expand="block"
          @click="goToPage('Create')"
          >Create Product</ion-button
        >
        <ion-button
          class="feature-buttons"
          expand="block"
          @click="goToPage('Search')"
          >Edit Product</ion-button
        >
        <ion-button
          class="feature-buttons"
          expand="block"
          @click="goToPage('Login')"
          >Logout</ion-button
        >
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonButton,
  IonToolbar,
  IonPage,
  IonTitle,
  IonText,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { Storage } from "@capacitor/storage";

export default defineComponent({
  name: "Home",
  components: {
    IonContent,
    IonHeader,
    IonToolbar,
    IonPage,
    IonTitle,
    IonButton,
    IonText,
  },
  data() {
    return {
      username: "",
      password: null,
      responseStatus: "",
      loading: false,
      userId: "",
    };
  },
  mounted() {
    this.checkIfLoggedIn();
    this.getUserId();
  },
  methods: {
    goToPage(param) {
      if (param == "Login") {
        this.clearLogin();
        this.$router.push({
          name: param,
          params: { token: this.$route.params.token },
          replace: true,
        });
      } else {
        this.$router.push({
          name: param,
          params: { token: this.$route.params.token },
        });
      }
    },
    async checkIfLoggedIn() {
      const { value } = await Storage.get({ key: "token" });

      if (!value) this.goToPage("Login");
    },
    async clearLogin() {
      await Storage.remove({ key: "token" });
    },
    async getUserId() {
      const { value } = await Storage.get({ key: "userId" });
      this.userId = value;
    },
  },
});
</script>

<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 16px;
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
.feature-buttons {
  margin: 30px 0;
}
</style>