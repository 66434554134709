
import {
  IonContent,
  IonHeader,
  IonButton,
  IonToolbar,
  IonPage,
  IonTitle,
  IonText,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { Storage } from "@capacitor/storage";

export default defineComponent({
  name: "Home",
  components: {
    IonContent,
    IonHeader,
    IonToolbar,
    IonPage,
    IonTitle,
    IonButton,
    IonText,
  },
  data() {
    return {
      username: "",
      password: null,
      responseStatus: "",
      loading: false,
      userId: "",
    };
  },
  mounted() {
    this.checkIfLoggedIn();
    this.getUserId();
  },
  methods: {
    goToPage(param) {
      if (param == "Login") {
        this.clearLogin();
        this.$router.push({
          name: param,
          params: { token: this.$route.params.token },
          replace: true,
        });
      } else {
        this.$router.push({
          name: param,
          params: { token: this.$route.params.token },
        });
      }
    },
    async checkIfLoggedIn() {
      const { value } = await Storage.get({ key: "token" });

      if (!value) this.goToPage("Login");
    },
    async clearLogin() {
      await Storage.remove({ key: "token" });
    },
    async getUserId() {
      const { value } = await Storage.get({ key: "userId" });
      this.userId = value;
    },
  },
});
