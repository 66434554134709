import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/create',
    name: 'Create',
    component: () => import('@/views/CreateProduct.vue')
  },
  {
    path: '/edit',
    name: 'Edit',
    component: () => import('@/views/EditProduct.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/SearchProduct.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
